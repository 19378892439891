import { getParamFromUrlString } from '../../../modules/modules'

type Props = {
  videoUrl: string
}
export default function VimeoPlayer(props: Props) {

  // 動画IDの取得
  function getVimeoId(): string {
    // props.videoUrlからパラメータを全て削除
    const url = new URL(props.videoUrl);
    url.search = ''; // クエリパラメータを削除
    const cleanUrl = url.href;
    const match = cleanUrl.match(/\/(\d+)$/); // 正規表現で数字部分を検索
    if (match && match[1]) {
      return match[1];
    } else {
      return "";
    }
  }

  return (
    <iframe
      src={`https://player.vimeo.com/video/${getVimeoId()}`}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    >
    </iframe>
  );
}