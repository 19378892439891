import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { YTApiAtom } from "../atom/GlobalAtom"

// 背景のstateを管理
type Background = State & {
  loaded: () => void;
  // deactivate: () => void;
};

type State = {
  isLoaded: boolean;
};

const useYTApi = (): Background => {

  const isLoaded = useRecoilValue(YTApiAtom);
  const setLoaded = useSetRecoilState(YTApiAtom);

  const loaded = useCallback(() => setLoaded(true), []);
  // const deactivate = useCallback(() => setIsInitialized(false), []);

  return {
    isLoaded,
    loaded,
    // deactivate,
  };
}
export default useYTApi;