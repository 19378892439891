import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from 'react';
import useBackground from '../../../hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import SecondaryHeading from 'components/headings/SecondaryHeading'

import concept_flame from "assets/img/about/concept_flame.svg";
import concept_flame_sp from "assets/img/about/concept_flame_sp.svg";
import concept_bg from "assets/img/about/concept_bg.jpg";
import profile_image from "assets/img/about/profile_image.png";
import symbol from "assets/img/common/symbol.svg";
import animation from "assets/img/about/animation.png";
import vfx from "assets/img/about/vfx.png";
import tdcg from "assets/img/about/3dcg.png";
import SnsIconList from "components/ui/SnsIconList";
import fv from "assets/img/home/reel.mp4";

import { Link } from 'react-router-dom';
import axios from 'axios'
import { d } from 'modules/cipher'
import { loaded, loading, qs, getParam, ISOvserver } from 'modules/modules'

type Props = {
}

const Index = (props: Props) => {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();

    const video = qs(".home_showreel_videos");
    video.addEventListener('loadedmetadata', handleMovieLoaded)
    function handleMovieLoaded() {
      setTimeout(() => {
        video.play();
      }, 500);
    }
    return () => {
      video.removeEventListener('loadedmetadata', handleMovieLoaded)
    }
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer.disconnect();
    };
  }, []);

  // aboutの情報を取得
  const [aboutInfo, setAboutInfo] = useState(newAboutInfo("", "", ""));
  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  // 記事詳細を取得
  useEffect(() => {

    loading();

    // 通信に失敗した時のために初期値を設定
    let businessDomain = "ライブ演出 / モーショングラフィックス / VFX / 3DCG\nコンポジット / アニメーション / ディレクション";
    let software = "After Effects / Cinema4D / Zbrush / Unreal Engine";
    let introduction = "株式会社WiLDCARD代表\n映像ディレクター／ライブ演出／コンポジット\n\n2016年に行われたRADIO FISHワンマンライブ『PERFECT SUMMER』にて\nライブ演出映像を担当し、CGクリエイターとしてデビュー。\n自身がダンサーとしてFISHBOY氏に師事していたことから\n音とシンクロさせた映像を得意とし、\n主にアーティストのライブ演出映像を手掛けている。\n\n2020年からアーティスト『RepezenFoxx』のCGデザイナーとしてチームに加入。\nほかにもイベント用映像からMV制作に渡り幅広く作品も制作。\n\n後進育成と映像業界への貢献を目標とし、\n2020年より自身のYouTubeチャンネルを開設。\n2023年現在、TikTokのフォロワーは4.5万人を超えている。";

    const uri = apiDomain + "/wp-json/wp/v2/about";

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {

        // 情報の取得
        businessDomain = res.data.business_domain;
        software = res.data.software;
        introduction = res.data.introduction;

        // 記事情報のセット
        setAboutInfo(newAboutInfo(businessDomain, software, introduction));

        loaded();
      })
      .catch(function (e) {
        setAboutInfo(newAboutInfo(businessDomain, software, introduction));
        loaded();
      })
  }, []);

  function newAboutInfo(businessDomain: string, software: string, introduction: string) {
    return { businessDomain: businessDomain, software: software, introduction: introduction };
  }

  // 改行コードをbrタグに変換し、jsxにする関数
  function nl2br(text: string) {
    const regex = /(\n)/g;
    const result = text.split(regex).map((line, index) => {
      if (line.match(regex)) {
        return <br key={index} />;
      } else {
        return line;
      }
    });
    return result;
  }

  return (
    <>
      <Helmet>
        <title>ABOUT｜WiLDCARD.inc</title>
        <meta name="description" content="映像制作の「万能の手札」。アーティストライブ映像、TVCMの映像制作を手掛ける映像制作会社、株式会社WiLDCARD。アニメーション、VFX、3DCGなど幅広い映像に対応。映像制作に関するご相談、お待ちしております。" />
        <meta property="og:url" content="https://wildcard.jp/about" />
        <meta property="og:title" content="ABOUT｜WiLDCARD.inc" />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__about" className="l__content_width">
          <PrimaryHeading letter="ABOUT" letter_ja="会社概要" />

          <div className="about_inner">
            <div className="concept js__load_required">
              <h3 className="concept_head">CONCEPT</h3>
              <img className="concept_flame" src={concept_flame} alt="" />
              <img className="concept_flame_sp" src={concept_flame_sp} alt="" />
              <img className="concept_bg" src={concept_bg} alt="" />
              <span className="concept_bg_gradation"></span>
            </div>
            <div className="message c__js_fade_delay">
              <img className="symbol" src={symbol} alt="" />
              <p className="message_text js__delay_child delay0">
                株式会社WiLDCARDは<span className="u__sp_only">、</span><br className="u__sp_none" />
                「万能の手札」の名を冠する映像制作会社。<br />
                <br />
              </p>
              <p className="message_text js__delay_child delay1">
                数々のMVやライブ演出映像等の制作実績を持ち<span className="u__sp_only">、</span><br className="u__sp_none" />
                音とシンクロした表現によって<br className="u__sp_none" />
                音楽の魅力を最大限引き立たせることが得意です。<br />
                <br />
              </p>
              <p className="message_text js__delay_child delay2">
                ここ一番で頼れる「手札」として<span className="u__sp_only">、</span><br className="u__sp_none" />
                制作におけるあらゆる技法を駆使しながら<span className="u__sp_only">、</span><br className="u__sp_none" />
                皆様の想いを映像で伝えていきます。<br />
                <br />
              </p>
            </div>
            <div className="serveices c__js_fade">
              <div className="head_wrapper">

                <SecondaryHeading letter="SERVICES" letter_ja="事業概要" />
              </div>
              <p className="serveices_text">
                ご要望をできるだけ的確に映像化できるよう、丁寧にヒアリングを行います。<br />
                アニメーションから3DCGまで、幅広く対応可能です。
              </p>
              <div className="services_icon_wrapper c__js_fade_delay">
                <div className="services_icon js__delay_child delay0">
                  <div className="img_wrapper">
                    <img src={animation} alt="" />
                  </div>
                  <p className="services_icon_text">アニメーション</p>
                </div>
                <div className="services_icon js__delay_child delay1">
                  <div className="img_wrapper">
                    <img src={vfx} alt="" />
                  </div>
                  <p className="services_icon_text">VFX</p>
                </div>
                <div className="services_icon js__delay_child delay2">
                  <div className="img_wrapper">
                    <img src={tdcg} alt="" />
                  </div>
                  <p className="services_icon_text">3DCG</p>
                </div>
              </div>
              <div className="serveces_description_wrapper">
                <div className="serveces_description_inner">
                  <h3 className="serveces_description_head">事業領域</h3>
                  <p className="serveces_description">
                    {nl2br(aboutInfo.businessDomain)}
                  </p>
                </div>
                <div className="serveces_description_inner">
                  <h3 className="serveces_description_head">使用ソフト</h3>
                  <p className="serveces_description">{nl2br(aboutInfo.software)}</p>
                </div>
              </div>

            </div>
            <div className="profile c__js_fade">
              <SecondaryHeading letter="PROFILE" letter_ja="代表について" />
              <div className="profile_wrapper c__js_fade_delay">
                {/* プロフィール写真と会社概要を掲載 */}
                <div className="profile_l_pc js__delay_child delay0">
                  <img className="profile_image" src={profile_image} alt="" />
                  <div className="company_summary_wrapper">
                    <p className="company_summary_head">
                      会社名<br />
                      代表取締役<br />
                      事業内容<br />
                      設立<br />
                      資本金<br />
                      所在地
                    </p>
                    <p className="company_summary_text">
                      株式会社WiLDCARD<br />
                      鈴木 玲音<br />
                      映像制作<br />
                      2022年12月28日<br />
                      100万円<br />
                      埼玉県
                    </p>
                  </div>
                </div>
                <div className="profile_r_pc js__delay_child delay1">
                  <h3 className="ceo_name">Reo Suzuki</h3>
                  <p className="ceo_text">
                    {nl2br(aboutInfo.introduction)}
                  </p>
                  <SnsIconList />
                  <div className="company_summary_wrapper">
                    <p className="company_summary_head">
                      会社名<br />
                      代表取締役<br />
                      事業内容<br />
                      設立<br />
                      資本金<br />
                      所在地
                    </p>
                    <p className="company_summary_text ">
                      株式会社WiLDCARD<br />
                      鈴木 玲音<br />
                      映像制作<br />
                      2022年12月28日<br />
                      100万円<br />
                      埼玉県
                    </p>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div className="next_info_wrapper c__js_fade_delay">

            <Link to={"/works"} className="next js__delay_child delay0" onClick={() => background.deactivate()}>
              <h3 className="next_head">NEXT</h3>
              <p className="next_text">制作実績を見る</p>
            </Link>


            <Link to={"/works"} className="movie_wrapper js__delay_child delay1" onClick={() => background.deactivate()}>
              <video className="home_showreel_videos active" src={fv} muted loop playsInline autoPlay ></video>
            </Link>
          </div>




        </main>
      </motion.div>
    </>
  );
}
export default Index;