import { useEffect, useState, useMemo } from 'react';
import { qsAll, removeClass, addClass, getParam, } from '../../../modules/modules'
import axios from 'axios';
import { d } from '../../../modules/cipher'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import { useModal } from 'react-hooks-use-modal';
import YoutubeModal from "components/layouts/videos/YoutubeModal";
import YoutubeAutoPlayer from "components/layouts/videos/YoutubeAutoPlayer";

type Props = {
}

// let behindScrollPosition = 0;

export default function Showreel(props: Props) {

  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [behindScrollPosition, setBehindScrollPosition] = useState(0);

  // モーダル制御
  const [Modal, modalOpen, modalClose, isModalOpen] = useModal('root', {
    preventScroll: true,
    components: {
      Overlay: () => {
        return (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
            }}
          />
        );
      },
    },
  });

  function handleModalOpen() {
    // console.log("document.documentElement.scrollTop", document.documentElement.scrollTop)
    let test = document.documentElement.scrollTop || document.body.scrollTop;
    setBehindScrollPosition(test)
    // behindScrollPosition = document.documentElement.scrollTop || document.body.scrollTop; // iphoneで一番上に戻ってしまうのを防ぐ
    // behindScrollPosition = document.documentElement.scrollTop
    // console.log("scrollTop", test)
    modalOpen();
    // document.documentElement.scrollTop = test
  }

  function handleModalClose() {
    // document.documentElement.scrollTop = behindScrollPosition
    modalClose();
    // document.documentElement.scrollTop = behindScrollPosition
    // document.body.scrollTop = behindScrollPosition
    // console.log("scrollTop", behindScrollPosition)
    // window.scrollTo({
    //   top: 2000,
    // });
    setTimeout(() => {
      window.scrollTo({
        top: behindScrollPosition,
      });
    }, 100);
    // document.documentElement.scrollTop = scrollTop; // iphoneで一番上に戻ってしまうのを防ぐ
  }

  // 記事セット
  const [showreels, setShowreelList] = useState([]);

  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";
    const query = `showreel_list${previewModeQuery}`
    const uri = apiDomain + "/wp-json/wp/v2/" + query;
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    const uri = composeApiUrl();
    axios({
      method: 'get',
      url: uri,
      timeout: 5000
    })
      .then(function (res) {
        const showreelList = res.data.contents;
        setShowreelList(showreelList);

        // 動画IDの初期値をセット
        if (showreelList.length >= 1) {
          setYoutubeUrl(showreelList[0].youtube_url)
        }
      })
      .catch(function (error) {
      })
      .finally(function () {
      });
  }, []);

  function handleYearClick(e: any, ytUrl: string) {
    const targets = qsAll(".year")
    for (const elm of targets) {
      removeClass(elm, "active")
    }
    addClass(e.target, "active")
    setYoutubeUrl(ytUrl)
  }

  const showYearButtons = useMemo(() => {

    let postTitle;
    let activeClass = "";
    if (showreels?.length >= 1) {
      return (
        showreels.map((article: any, index: number) => {
          // タイトルの取得
          postTitle = String(article.title);
          activeClass = index === 0 ? "active" : activeClass = ""
          return (
            <button key={index} className={`year ${activeClass}`} onClick={(e) => handleYearClick(e, article.youtube_url)}>{postTitle}</button>
          )
        })
      )
    }
  }, [showreels])

  return (
    <>
      <div id="home_showreel">
        <div className="home_showreel_inner c__js_fade">

          <PrimaryHeading letter="SHOWREEL" />

          <div id="showreel_movie_wrapper" className="">
            <div className="play_button" onClick={handleModalOpen}></div>
            {youtubeUrl && <YoutubeAutoPlayer videoUrl={youtubeUrl} stop={isModalOpen} />}
          </div>

          <div className='years'>
            {showYearButtons}
          </div>
        </div>
      </div>
      <YoutubeModal modal={Modal} handleModalClose={handleModalClose} videoUrl={youtubeUrl} />
    </>
  );
}