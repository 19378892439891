import { useEffect, useState } from "react";
import { useModal } from 'react-hooks-use-modal';
import { loaded, loading, qsAll, removeClass, addClass, qs, getParam, getParamFromUrlString, optionalISOvserver } from '../../../modules/modules'
import useYTApi from 'hooks/useYTApi'

type Props = {
  videoUrl: string
  stop: boolean
}
export default function YoutubeAutoPlayer(props: Props) {

  const [youtubeInitialized, setYoutubeInitialized] = useState(false);
  const [YTPlayer, setYTPlayer] = useState();

  const ytApi = useYTApi();

  // 初回はYouTubeのscriptを読み込み、プレイヤーを初期化
  useEffect(() => {
    
    // 既にapiがブラウザに読み込まれていたらapiのscriptタグは作らずプレイヤーの初期化のみ行う
    if (ytApi.isLoaded) {
      initYoutubeIframe()
      return
    }

    // apiの読み込み
    const scripttag = document.createElement('script');
    scripttag.setAttribute('class', 'youtube_api');
    scripttag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    // @ts-ignore
    firstScriptTag.parentNode.insertBefore(scripttag, firstScriptTag);
    // @ts-ignore
    window.onYouTubeIframeAPIReady = () => {
      initYoutubeIframe();
      ytApi.loaded();
    };
  }, []);

  // 別の動画が選択されたらプレイヤーを削除し、再度プレイヤーを初期化
  useEffect(() => {
    if (youtubeInitialized) {
      qsAll("#player_inner").forEach((element: any) => {
        element.remove();
      });
      initYoutubeIframe();
    }
  }, [props.videoUrl]);

  // モーダルの開閉が切り替わったら非活性活性を切り替える
  useEffect(() => {
    if (youtubeInitialized) {
      const playerWrapper = qs("#player_wrapper");
      const player = qs("#player_wrapper iframe");
      if (props.stop) {
        // @ts-ignore
        YTPlayer.pauseVideo();
        addClass(playerWrapper, "disabled");
      } else {
        // @ts-ignore
        YTPlayer.playVideo();
        removeClass(playerWrapper, "disabled");
      }
    }
  }, [props.stop]);

  // 画面の外に行ったら一時停止
  useEffect(() => {
    const target = qs("#player_wrapper");
    let observer = new IntersectionObserver(callback);
    observer.observe(target);
    return () => {
      observer.disconnect();
    };
    function callback(entries: any) {

      for (const elm of entries) {
        if (elm.isIntersecting) {
          if (youtubeInitialized) {
            // @ts-ignore
            YTPlayer.playVideo();
          }
        } else {
          if (youtubeInitialized) {
            // @ts-ignore
            YTPlayer.pauseVideo();
          }
        }
      }
    }
  }, [youtubeInitialized]);

  // 動画IDの取得
  function getId() {
    return getParamFromUrlString(props.videoUrl, "v")
  }

  // プレイヤーを初期化する関数
  function initYoutubeIframe() {

    setYoutubeInitialized(false);

    // ラッパーの初期化
    const playerWrapper = qs("#player_wrapper");
    const divtag = document.createElement('div');
    divtag.setAttribute('id', 'player_inner');
    playerWrapper.appendChild(divtag);

    // Playerの初期化
    // @ts-ignore
    const player = new YT.Player('player_inner', {
      videoId: getId(),
      playerVars: {
        autoplay: 1, // 自動再生
        mute: 1, // 音声をミュート
        playsinline: 1, // インライン再生
        loop: 1, // 動画を繰り返し再生
        controls: 0, // コントロール非表示
        disablekb: 1, // キーボードでの操作無効
        enablejsapi: 1, // JS API使用
        modestbranding: 1, // ロゴ非表示
        rel: 0, // 関連動画非表示
        showinfo: 0, // タイトルなど非表示
        fs: 0, // フルスクリーンボタン非表示
        iv_load_policy: 3, // アノテーション非表示
        cc_load_policy: 0, // 字幕非表示
        frameborder: 0, // 枠線非表示
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange
      }
    });

    function onPlayerReady(event: any) {
      setYTPlayer(player)
      setYoutubeInitialized(true);
      event.target.setPlaybackQuality("medium")
      event.target.mute()
      event.target.playVideo()
    }
    function onPlayerStateChange(event: any) {
      // @ts-ignore
      if (event.data === YT.PlayerState.ENDED) {
        event.target.playVideo()
      }
    }
  }

  return (
    <>

      <div id="player_wrapper"></div>

    </>
  );
}