import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loaded, loading, getParam } from '../../../modules/modules'
import axios from 'axios';
import { d } from '../../../modules/cipher'
import useBackground from '../../../hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import arrowWh from "assets/img/icon/wildcard_arrow_wh.svg";
import arrowRd from "assets/img/icon/wildcard_arrow_rd.svg";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

type Props = {
}

export default function News(props: Props) {

  // 背景操作用
  const background = useBackground();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = 3;
  const currentPage = 1;
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `article_list?limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = apiDomain + "/wp-json/wp/v2/" + query;
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    loading();
    background.deactivate();
    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 5000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
      })
      .catch(function (error) {
      })
      .finally(function () {
        loaded();
        background.activate();
      });
  }, [routeUniqueKey]);


  function showArticles(): any {
    let postTitle;
    let postUrl;
    let publishedDate;
    if (articles?.length) {
      return (
        articles.map((article: any, index: number) => {
          postTitle = article.title; // タイトルを設定
          postUrl = "/news/" + article.id; // URLを設定
          publishedDate = dayjs(article.date).format('YYYY.MM.DD');
          return (
            <Link to={postUrl} className="anchor" title={postTitle} onClick={() => background.deactivate()} key={index}>
              <span className="date">{publishedDate}</span>
              <span className="title">{postTitle}</span>
            </Link>
          )
        })
      )
    }
  }










  return (
    <>
      <div id="home_news" className='c__js_fade'>

        <div className="heading">
          <PrimaryHeading letter="NEWS" />
          <Link to={"/news"} className="link" onClick={() => background.deactivate()}>
            <p className="text">
              view all
              <img src={arrowWh} className="wh" alt="" />
              <img src={arrowRd} className="rd" alt="" />
            </p>

          </Link>
        </div>

        <ul className="news_list">
          {showArticles()}
          {/* <NewsList limit={12} pagenate={true} /> */}
          {/* {dummyArticles()}
          {dummyArticles()} */}
        </ul>


      </div>
    </>
  );
}