import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { qs, scrollTo, getHeaderHeight, toggleClass, hasClass, setSvh, removeClass, addClass, } from 'modules/modules'
import SnsIconList from 'components/ui/SnsIconList'
import logo_wh from 'assets/img/common/logo_hr.svg'
import useBackground from 'hooks/useBackground'
import useCurrentPage from 'hooks/useCurrentPage'

type Props = {
}

let toggle: HTMLElement;
let sp_menu: HTMLElement;
let sp_header_logo: HTMLElement;

export default function HeaderSp(props: Props) {

  const background = useBackground();
  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  /*=======================================
    SPメニュー制御
  =======================================*/
  // 要素取得
  useEffect(() => {
    toggle = qs("#header_toggle");
    sp_menu = qs("#sp_menu");
    sp_header_logo = qs("#sp_header_logo");
    return arrowScroll();
  }, []);
  // メニュー開閉
  function toggleMenu() {
    setSvh();
    toggleClass(toggle, "active");
    toggleClass(sp_menu, "active");
    toggleClass(sp_header_logo, "disabled");
    if (hasClass(toggle, "active")) {
      preventScroll();
    } else {
      arrowScroll();
    }
  }
  function preventScroll() {
    document.addEventListener("wheel", preventDef, { passive: false });
    document.addEventListener("touchmove", preventDef, { passive: false });
  }
  function arrowScroll() {
    document.removeEventListener("wheel", preventDef);
    document.removeEventListener("touchmove", preventDef);
  }
  function preventDef(e: any) {
    e.preventDefault();
  }

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink(newPageName: string) {
    if (currentPage !== newPageName) background.deactivate();
    toggleMenu();
  };
  // 背景非活性化
  function handleClickLogo(newPageName: string) {
    if (currentPage !== newPageName) { 
      background.deactivate() 
    } else { 
      scrollTo(0);
    }
    ;
  };

  return (
    <>
      <div id="sp_header">
        <Link to="/" id="sp_header_logo" onClick={() => handleClickLogo("home")}>
          <img src={logo_wh} className="white" alt="" width={140} height={61} />
        </Link>
        <button id="header_toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <nav id="sp_menu" className="">
        <div className="menu_inner">

          <img src={logo_wh} className="sp_menu_logo" alt="" width={140} height={61} />

          <Link to="/" className="link_name" onClick={() => handleClickLink("home")}>
            HOME
          </Link>

          <Link to="/works" className="link_name" onClick={() => handleClickLink("works")}>
            WORKS
          </Link>

          <Link to="/about" className="link_name" onClick={() => handleClickLink("about")}>
            ABOUT
          </Link>

          <Link to="/news" className="link_name" onClick={() => handleClickLink("news")}>
            NEWS
          </Link>

          <Link to="/contact" className="link_name" onClick={() => handleClickLink("contact")}>
            CONTACT
          </Link>
          <SnsIconList variantClassName="" />
        </div>
      </nav>
    </>
  );
}