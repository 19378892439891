import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { motion } from "framer-motion"
import { d } from 'modules/cipher'
import { Helmet } from "react-helmet-async"
import noimage from 'assets/img/common/noimage.jpg'
import { loaded, loading, qs, getParam, ISOvserver } from 'modules/modules'
import useBackground from 'hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryArticle from 'components/layouts/articles/PrimaryArticle'

type Props = {
}

const Show = (props: Props) => {

  const background = useBackground();

  const { id } = useParams(); //ルーティングで使っているパラメータ
  const [article, setArticle] = useState(newArticle("", "", [], "", "", "", "", "", []));
  const [prevArticleId, setPrevArticleId] = useState("");
  const [nextArticleId, setNextArticleId] = useState("");

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  // 記事詳細を取得
  useEffect(() => {
    loading();
    background.deactivate();

    let postTitle: string;
    let publishedDate: string;
    let postCategories: string[];
    let postImage: string;
    let subImages: string[];
    let youtubeUrl: any;
    let vimeoUrl: any;
    let postContent: string;
    let excerpt: string;

    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `work_detail/?id=${id}${previewQuery}`;
    const uri = apiDomain + "/wp-json/wp/v2/" + query;

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {

        // タイトルの取得
        postTitle = String(res.data.title);

        //公開日
        publishedDate = dayjs(res.data.date).format('YYYY.MM');
        if (publishedDate === "Invalid Date") { publishedDate = dayjs().format('YYYY.MM') } // 無効な日付だった場合はとりあえず現在の年月

        // カテゴリの取得
        postCategories = res.data.cat_names;

        // 本文の取得
        postContent = res.data.content;

        // 画像系の取得
        postImage = img_src(res.data);
        subImages = subImagesSrc(res.data.sub_images);

        // 動画系URLの取得
        youtubeUrl = res.data.youtube_url;
        vimeoUrl = res.data.vimeo_url;

        // 抜粋の取得
        excerpt = res.data.content.replace(/(<([^>]+)>)/gi, '');

        // 記事情報のセット
        setArticle(newArticle(postTitle, publishedDate, postCategories, postImage, postContent, excerpt, youtubeUrl, vimeoUrl, subImages));
        if (res.data.prev) setPrevArticleId(res.data.prev.id);
        if (res.data.next) setNextArticleId(res.data.next.id);

        loaded();
        background.activate();
      })
      .catch(function (e) {
        loaded();
        background.activate();
        window.location.href = "/notfound"
      })
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer.disconnect();
    };
  }, []);

  function newArticle(title: string, published_at: string, categories: string[], image_url: string, content: string, excerpt: string, youtubeUrl: string, vimeoUrl: string, subImages: string[]) {
    return { title: title, published_at: published_at, categories: categories, image_url: image_url, content: content, excerpt: excerpt, youtubeUrl: youtubeUrl, vimeoUrl, subImages };
  }

  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  function subImagesSrc(images: any): string[] {
    if (!images) return []; // nullであれば空の配列を返す
    const length = Object.keys(images).length;
    const returnImages: string[] = [];
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const key = Object.keys(images)[i];
        const img = images[key]
        if (img) returnImages.push(images[key]);
      }
      return returnImages;
    } else {
      return [];
    }
  }

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta property="og:image" content={article.image_url} />
        <meta property="og:title" content={article.title + " WORKS - WiLDCARD.inc"} />
        <title>{article.title} WORKS - WiLDCARD.inc</title>
        <meta property="og:url" content={"https://wildcard.jp/works/" + id} />
        {article.excerpt &&
          <>
          <meta property="og:description" content={"WORKS - WiLDCARD.inc " + article.excerpt} />
          <meta name="description" content={"WORKS - WiLDCARD.inc " + article.excerpt} />
          </>
        }
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p_works_show" className="js__load_required l__content_width">

          <PrimaryHeading letter="WORKS" letter_ja="制作実績" modifire='small' />
          <PrimaryArticle article={article} prevArticleId={prevArticleId} nextArticleId={nextArticleId} mode={"works"} />

        </main>
      </motion.div>
    </>
  );
}
export default Show;