import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { ISOvserver } from '../../../modules/modules'
import useBackground from '../../../hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'

type Props = {
}

const Submitted = (props: Props) => {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();
  useEffect(() => {
    background.activate();
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>送信完了｜WiLDCARD.inc</title>
        <meta name="description" content={"お問い合わせ送信完了｜WiLDCARD.inc"} />
        <meta property="og:url" content={"https://wildcard.jp/contact/submitted"} />
        <meta property="og:title" content={"送信完了｜WiLDCARD.inc"} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__contact_submitted" className="l__content_width">
          <PrimaryHeading letter="THANKS" letter_ja="送信完了" />
          <p className="c__common_text submitted_description">
            この度はお問い合わせくださりありがとうございます。<br />
            2～3日以内にお返事いたしますので、<br />
            恐れ入りますが今しばらくお待ちくださいますよう、お願い申し上げます。
          </p>
          <Link to={"/"} className="c__secondary_button" id="submit" onClick={() => background.deactivate()} >
            TOPへ戻る
          </Link>
        </main>
      </motion.div>

    </>
  );
}
export default Submitted;