import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { qs, qsAll } from 'modules/modules'
import useBackground from 'hooks/useBackground'
import YoutubePlayer from 'components/layouts/videos/YoutubePlayer'
import VimeoPlayer from 'components/layouts/videos/VimeoPlayer'

// @ts-ignore
import { LuminousGallery } from 'luminous-lightbox';

type Props = {
  article: any
  mode: string
  prevArticleId: any
  nextArticleId: any
}
export default function PrimaryArticle(props: Props) {

  const background = useBackground();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  /*=======================================
    twitter埋め込み動かない対策
  =======================================*/
  const script = document.createElement('script');
  script.src = "https://platform.twitter.com/widgets.js";
  useEffect(() => {
    // twitterまわりの処理
    let twitter_widget_wrapper = qs("#twitter_widget_wrapper");
    twitter_widget_wrapper.appendChild(script);

    // 画像拡大機能
    new LuminousGallery(qsAll(".js__luminous"));
    return () => {
      // twitterまわりのクリーンアップ
      twitter_widget_wrapper.removeChild(script);


    };
  }, [props.article]);


  /*=======================================
    記事内リンクに対しての設定
  =======================================*/
  // aタグに対してホバーの反応と外部リンクで開く設定を追加
  useEffect(() => {
    const selector: string = ".c__article_body a"
    setTimeout(() => {
      const anchors = qsAll(selector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);
  }, []);


  function prevPager() {
    const cls = props.prevArticleId === "" ? " disabled" : "";
    return (
      <Link to={`/${props.mode}/${props.prevArticleId}`} className={"pager_link_prev" + cls} onClick={() => background.deactivate()} title="前の記事">
        PREV
      </Link>
    )
  }
  function nextPager() {
    const cls = props.nextArticleId === "" ? " disabled" : "";
    return (
      <Link to={`/${props.mode}/${props.nextArticleId}`} className={"pager_link_next" + cls} onClick={() => background.deactivate()} title="次の記事">
        NEXT
      </Link>
    )
  }
  function twitterShareHref() {
    const url = encodeURIComponent(window.location.href);
    const truncatedTitle = props.article.title.length > 34 ? (props.article.title.substring(0, 34) + "...") : props.article.title;
    const title = encodeURIComponent(truncatedTitle + "｜株式会社WiLDCARD");
    const hashtag = encodeURIComponent("wildcard_inc");
    return (
      `https://twitter.com/share?url=${url}&text=${title}&hashtags=${hashtag}`
    )
  }

  return (
    <>

      <article className="c__article_wrapper">
        <div className="article_head_info">
          <p className="article_published_at">{props.article?.published_at}</p>

          {props.mode == "works" && props.article.categories?.length >= 1 &&
            <p className="article_category">
              {
                props.article.categories.map((category: any, index: number) => {
                  return (
                    <span key={index} className="u__ib">#{category}</span>
                  )
                })
              }
            </p>
          }
        </div>

        <h1 className="article_title">{props.article?.title}</h1>

        {props.mode == "works" && props.article?.youtubeUrl &&
          <div className="article_thumbnail">
            <YoutubePlayer videoUrl={props.article.youtubeUrl} />
          </div>
        }

        {props.mode == "works" && props.article?.vimeoUrl &&
          <div className="article_thumbnail">
            <VimeoPlayer videoUrl={props.article.vimeoUrl} />
          </div>
        }

        {/* youtubeの動画がない場合はサムネイルを表示 */}
        {props.mode == "works" && !props.article?.youtubeUrl &&
          <div className="article_thumbnail">
            <img src={props.article.image_url} alt={props.article.title} />
          </div>
        }

        {props.article.subImages?.length >= 1 &&
          <div className="sub_images">
            {
              props.article.subImages.map((img: any, index: number) => {
                return (
                  <a className="sub_image js__luminous" href={img} key={index}>
                    <img className="" src={img} />
                  </a>
                )
              })
            }
          </div>
        }

        <div className="c__article_body" dangerouslySetInnerHTML={{ __html: props.article?.content }} />

        <a className="c__sns_share"
          href={twitterShareHref()} target="_blank" rel="noopener noreferrer" title="Xでシェアする"><span className="sns_share_text">SHARE</span></a>

        <div className="article_prev_next">
          {prevPager()}

          <Link to={"/" + props.mode} className="c__button_underline_hover" onClick={() => background.deactivate()} title="一覧に戻る">
            BACK TO ALL
          </Link>

          {nextPager()}

        </div>

      </article>

      <div id="twitter_widget_wrapper"></div>

    </>
  );
}