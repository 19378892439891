import { atom } from 'recoil';
const BackgroundAtom = atom({
  key: "BackgroundAtom",
  default: false,
})
const YTApiAtom = atom({
  key: "YTApiAtom",
  default: false,
})
export {
  BackgroundAtom,
  YTApiAtom
};