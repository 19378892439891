
import './assets/scss/App.scss';
import { setSvh, setStaticSvh } from './modules/modules'
import Background from './components/layouts/background/Background';
import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';

const App = () => {

  useEffect(() => {
    setSvh();
    setStaticSvh();
    window.addEventListener('resize', setSvh);
    return () => {
      window.removeEventListener('resize', setSvh);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:image" content="https://wildcard.jp/ogp.jpg" />
        <meta property="og:title" content="WiLDCARD.inc" />
        <meta property="og:description" content="アーティストライブ映像、TVCMの映像制作を手掛ける映像制作会社、株式会社WiLDCARD。アニメーション、VFX、3DCG、クリーチャーモデリングなど幅広い映像に対応。映像制作に関するご相談、お待ちしております。"></meta>
        <meta property="og:url" content="https://wildcard.jp/" />
        <title>WiLDCARD.inc｜映像制作の「万能の手札」。</title>
        <meta name="description" content="アーティストライブ映像、TVCMの映像制作を手掛ける映像制作会社、株式会社WiLDCARD。アニメーション、VFX、3DCG、クリーチャーモデリングなど幅広い映像に対応。映像制作に関するご相談、お待ちしております。" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <RecoilRoot>
        <Background />
      </RecoilRoot>
    </>
  );
}
export default App;