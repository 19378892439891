import { Link, useNavigate } from 'react-router-dom';
import useBackground from 'hooks/useBackground'
import SnsIconList from 'components/ui/SnsIconList'
import useCurrentPage from 'hooks/useCurrentPage'
import porygon_bg from '../../../assets/img/common/porygon_bg_3.svg'
import youtube_icon_wh from 'assets/img/icon/web_services/youtube_wh.svg';
import youtube_icon_cl from 'assets/img/icon/web_services/youtube_cl.svg';

type Props = {
}
export default function Footer(props: Props) {


  const background = useBackground();
  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink(newPageName: string) {
    if (currentPage !== newPageName) background.deactivate();
  };

  return (
    <>
      <footer id="footer" className={background.isActive ? " active" : ""}>
        <SnsIconList variantClassName="footer_sns" />

        <nav className="footer_nav">

          <a className="service_icon yt" href="https://www.youtube.com/@REOakaJOKER" target="_blank" rel="noopener noreferrer" >
            <img src={youtube_icon_wh} className="white" alt="youtube" />
            <img src={youtube_icon_cl} className="color" alt="youtube" />
          </a>

          <Link to="/works" onClick={() => handleClickLink("works")} className={currentPage === "works" ? " active" : ""}>
            WORKS
          </Link>

          <Link to="/about" onClick={() => handleClickLink("about")} className={currentPage === "about" ? " active" : ""}>
            ABOUT
          </Link>

          <Link to="/news" onClick={() => handleClickLink("news")} className={currentPage === "news" ? " active" : ""}>
            NEWS
          </Link>

          <Link to="/contact" onClick={() => handleClickLink("contact")} className={currentPage === "contact" ? " active" : ""}>
            CONTACT
          </Link>

          <Link to="/terms" onClick={() => handleClickLink("terms")} className={currentPage === "terms" ? " active" : ""}>
            TERMS
          </Link>
        </nav>
        <p className="copyright">
          <span className="u__ib">©︎WiLDCARD.inc </span>
          <span className="u__ib">All Rights Reserved.</span>
        </p>
        <img className="footer_background" src={porygon_bg} alt="" />
      </footer>


    </>
  );
}