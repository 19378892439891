import { useEffect, useState } from 'react';
import { qs, qsAll, ISOvserve, ISOvserverRepeats, removeClass, addClass, loaded, loading, initParallax } from "modules/modules"
import PrimaryButton from 'components/ui/PrimaryButton'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import WorksList from 'components/models/works/WorksList'

type Props = {
}

export default function Works(props: Props) {

  // アニメーション用
  useEffect(() => {
    const targetSelector = "#home_works";
    const fv = qs(targetSelector);
    const options = {
      rootMargin: "0px 0px -20% 0px",
    };
    const observer = ISOvserve(
      targetSelector,
      options,
      () => { addClass(fv, "active") },
      () => { removeClass(fv, "active") }
    )
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div id="home_works" className="js__load_required home_works">

        <PrimaryHeading letter="WORKS" />
        <WorksList limit={6} pagenate={false} category={false} />
        <PrimaryButton path="/works" letter="VIEW ALL WORKS" external={false} variant="" />

      </div>
    </>
  );
}
