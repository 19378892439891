import { Link } from 'react-router-dom';
import useBackground from 'hooks/useBackground'
import { useEffect, } from 'react';
import { geometryInit, geometryDestroy } from 'modules/geometry'
import contact_message from "assets/img/home/contact_message.svg";
import contact_message_frame from "assets/img/home/contact_message_frame.svg";
import contact_message_sp from "assets/img/home/contact_message_sp.svg";
import contact_message_frame_sp from "assets/img/home/contact_message_frame_sp.svg";
import contact_decoration from 'assets/img/home/contact_decoration.svg'
import contact_arrow from "assets/img/icon/wildcard_arrow_wh.svg";

type Props = {
}
export default function Contact(props: Props) {

  const background = useBackground();

  useEffect(() => {
    // requestAnimationFrameはタブを離れたら生成を止めてくれる
    geometryInit();
    return () => {
      geometryDestroy();
    }
  }, []);

  return (
    <>
      <div id="home_contact" className="c__js_fade">
        <Link to={"/contact"} className="link" onClick={() => background.deactivate()}>

          <div id="contact_bg_wrapper">

            <img src={contact_message_frame} className='contact_message_frame_pc' alt="" />
            <img src={contact_message} className='contact_message_pc' alt="" />
            <img src={contact_message_frame_sp} className='contact_message_frame_sp' alt="" />
            <img src={contact_message_sp} className='contact_message_sp' alt="" />
            <img src={contact_decoration} className='contact_decoration' alt="" />
            <img src={contact_arrow} className='contact_arrow' alt="" />
            <svg id="geometryangle" viewBox="0 0 1600 600" preserveAspectRatio="xMidYMid slice">
              <defs>
                <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0" colorInterpolation="sRGB">
                  <stop id="stop1a" offset="0%" stopColor="#791718"></stop>
                  <stop id="stop1b" offset="100%" stopColor="#791718"></stop>
                </linearGradient>
                <linearGradient id="grad2" x1="0" y1="0" x2="1" y2="0" colorInterpolation="sRGB">
                  <stop id="stop2a" offset="0%" stopColor="#791718"></stop>
                  <stop id="stop2b" offset="100%" stopColor="#791718"></stop>
                </linearGradient>
              </defs>
              <rect id="rect1" x="0" y="0" width="1600" height="600" stroke="none" fill="url(#grad1)"></rect>
              <rect id="rect2" x="0" y="0" width="1600" height="600" stroke="none" fill="url(#grad2)"></rect>
            </svg>

          </div>

        </Link>
      </div>
    </>
  );
}