import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { loaded, loading, getParam, addClass, } from '../../../modules/modules'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import { d } from 'modules/cipher'
import Pagenation from '../../ui/Pagenation'
import noimage from 'assets/img/common/noimage.jpg'
import useBackground from '../../../hooks/useBackground'
import CategoriesList from '../category/CategoriesList'

type Props = {
  limit: number
  pagenate: boolean
  category: boolean
}

export default function WorksList(props: Props) {

  // 背景操作用
  const background = useBackground();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = props.limit;
  const [currentPage, setCurrentPage] = useState(initCurrentPage());
  const [totalPages, setTotalPages] = useState(0);
  const [catId, setCatId] = useState(initCategory());
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  // 初回画面表示時に選択されているページ数を取得
  function initCurrentPage(): number {
    let params: number = parseInt(getParam("page"));
    if (params) {
      return params
    } else {
      return 1
    }
  }

  // 初回画面表示時に選択されているカテゴリを取得
  function initCategory(): string {
    let params: string = getParam("category");
    if (params) {
      return params
    } else {
      return ""
    }
  }

  function handleThumbClick(e: any) {
    background.deactivate();
    addClass(e.target, "active"); // アニメーション用
  }

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const categoryParam = catId === "" ? "" : `category=${catId}&`
    const query = `work_list?${categoryParam}limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = apiDomain + "/wp-json/wp/v2/" + query;
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    loading();
    background.deactivate();
    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 5000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
        setTotalPages(parseInt(res.data.total_pages)); //総ページ数 
      })
      .catch(function (error) {
      })
      .finally(function () {
        loaded();
        background.activate();
      });
  }, [currentPage, catId, routeUniqueKey]);

  // サムネURLの設定
  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  const showArticles = useMemo(() => {
    let postTitle;
    let postUrl;
    let postCategories: any;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles?.length >= 1) {
      return (
        <div className="c__articles_grid">
          {
            articles.map((article: any, index: number) => {
              // タイトルの取得
              postTitle = String(article.title);
              // URLの取得
              postUrl = "/works/" + article.id + previewQuery;
              // カテゴリの取得
              postCategories = article.cat_names
              return (
                <div key={index}>

                  <Link to={postUrl} className="anchor" title={postTitle} onClick={(e) => handleThumbClick(e)}>
                    <div className="img_wrapper">
                      <img src={img_src(article)} alt={postTitle} />
                    </div>
                    <p className="title">{postTitle}</p>
                  </Link>

                  {props.category &&
                    <p className="category">
                      {
                        postCategories.map((category: any, index: number) => {
                          return (
                            <span key={index} className="u__ib">#{category}</span>
                          )
                        })
                      }
                    </p>
                  }
                </div>
              )
            })}
        </div>
      )
    } else {
      return (
        <p className="article_notfound c__common_text">
          <span className="u__ib">データが見つかりませんでした。</span>
        </p>
      )
    }
  }, [articles])

  return (
    <>
      {props.category &&
        <CategoriesList categoryTaxonomy="work_category" catId={catId} setCatId={setCatId} setCurrentPage={setCurrentPage} />}
      <div className="js__load_required" key={routeUniqueKey}>
        {showArticles}
      </div>
      {props.pagenate && totalPages >= 2 &&
        <Pagenation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages} />}
    </>
  );
}