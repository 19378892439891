import { useLocation } from 'react-router-dom';

const useCurrentPage = (): string => {

  const location = useLocation();
  const pathHash = location.pathname + location.hash

  if (pathHash.includes("news")) {
    return "news"
  } else if (pathHash.includes("about")) {
    return "about"
  } else if (pathHash.includes("works")) {
    return "works"
  } else if (pathHash.includes("contact")) {
    return "contact"
  } else if (pathHash.includes("terms")) {
    return "terms"
  } else {
    return "home"
  }
}
export default useCurrentPage;