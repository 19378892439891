import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Router from 'config/Router';
import Header from 'components/layouts/header/Header';
import Footer from 'components/layouts/footer/Footer';
import useBackground from 'hooks/useBackground'
import porygon_bg from '../../../assets/img/common/porygon_bg_3.svg'

type Props = {
}
export default function Background(props: Props) {

  const background = useBackground();

  // 戻るボタンが押されたら背景をdeactivate
  useEffect(() => {
    window.addEventListener('popstate', () => { background.deactivate() })
    return () => {
      window.removeEventListener('popstate', () => { background.activate() })
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <div className={"background" + (background.isActive ? " active" : "")}>
          <img className={"polygon_bg_header" + (background.isActive ? " active" : "")} src={porygon_bg} alt="" />
          <div id="yeild" >
            <Header />
            <Router />
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}