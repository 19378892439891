import { } from 'modules/modules'
import twitter_icon_wh from 'assets/img/icon/web_services/x_wh.svg';
import twitter_icon_cl from 'assets/img/icon/web_services/x_wh.svg';
import youtube_icon_wh from 'assets/img/icon/web_services/youtube_wh.svg';
import youtube_icon_cl from 'assets/img/icon/web_services/youtube_cl.svg';
import tiktok_icon_wh from 'assets/img/icon/web_services/tiktok_wh.svg';
import tiktok_icon_cl from 'assets/img/icon/web_services/tiktok_cl_wh.svg';
type Props = {
  variantClassName?: string;
}
export default function SnsIconList(props: Props) {
  return (
    <>
      <div className={`c__sns_service_icon ${props.variantClassName}`}>
        <a className="service_icon yt" href="https://www.youtube.com/@REOakaJOKER" target="_blank" rel="noopener noreferrer" >
          <img src={youtube_icon_wh} className="white" alt="youtube" />
          <img src={youtube_icon_cl} className="color" alt="youtube" />
        </a>

        {/* <a className="service_icon tw" href="https://twitter.com/reo_wc_joker" target="_blank" rel="noopener noreferrer" >
          <img src={twitter_icon_wh} className="white" alt="twitter" />
          <img src={twitter_icon_cl} className="color" alt="twitter" />
        </a> */}

        {/* <a className="service_icon tk" href="https://www.tiktok.com/@reo_wc_joker?lang=ja-JP" target="_blank" rel="noopener noreferrer" >
          <img src={tiktok_icon_wh} className="white" alt="twitter" />
          <img src={tiktok_icon_cl} className="color" alt="twitter" />
        </a> */}
      </div>

    </>
  );
}