import head_cl from 'assets/img/common/head_decoration_wh.svg'

type Props = {
  letter: string
  letter_ja?: string
}

export default function SecondaryHeading(props: Props) {

  return (
    <h2 className="c__head_secondary">
      <span className="text">{props.letter}</span>
      <span className="text_ja">{props.letter_ja}</span>
      <img className="bg js__observe" src={head_cl} alt="" />
    </h2>
  );
}
