import { useEffect } from 'react';
import { qs, scrollTo, removeClass, addClass, ISOvserve } from 'modules/modules'

type Props = {
}

export default function PageTopButton(props: Props) {

  /*=======================================
    ページトップボタン制御
  =======================================*/
  useEffect(() => {
    const pagetopButton = qs('#pagetop_button');
    const targetSelector = '#pagetop_observer';
    const options = {
      rootMargin: "170px 0px 0px 0px",
    };
    const observer = ISOvserve(
      targetSelector,
      options,
      () => { removeClass(pagetopButton, 'active') },
      () => { addClass(pagetopButton, 'active') }
    )
    return () => { observer.disconnect(); };
  }, []);
  // ボタンクリック時は一番上にスクロール
  function handlePagetopClick(e: any) {
    e.preventDefault();
    scrollTo(0);
  };

  return (
    <>
      <div id="pagetop_button">
        <button onClick={(e) => { handlePagetopClick(e) }}></button>
      </div>
      <div id="pagetop_observer"></div>
      
    </>
  );
}