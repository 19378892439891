import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../components/pages/home/Index';
import Contact from '../components/pages/contact/Index';
import ContactSubmitted from '../components/pages/contact/Submitted';
import ArticlesIndex from '../components/pages/news/Index';
import ArticlesShow from '../components/pages/news/Show';
import WorksIndex from '../components/pages/works/Index';
import WorksShow from '../components/pages/works/Show';
import About from '../components/pages/about/Index';
import Terms from '../components/pages/terms/Index';
import NotFound from '../components/pages/notfound/Index';
import { AnimatePresence } from "framer-motion";

type Props = {
}

function Router(props: Props) {
  const location = useLocation();
  return (
    <>
      <AnimatePresence mode="wait" initial={true} onExitComplete={() => document.documentElement.scrollTop = 0 }>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/works" element={<WorksIndex />} />
          <Route path="/works/:id" element={<WorksShow />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<ArticlesIndex />} />
          <Route path="/news/:id" element={<ArticlesShow />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/submitted" element={<ContactSubmitted />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};
export default Router;