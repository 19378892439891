import { Link, useNavigate } from 'react-router-dom';
import SnsIconList from 'components/ui/SnsIconList'
import logo_wh from 'assets/img/common/logo_hr.svg'
import useBackground from 'hooks/useBackground'
import useCurrentPage from 'hooks/useCurrentPage'

type Props = {
}

export default function HeaderPc(props: Props) {

  const background = useBackground();
  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink(newPageName: string) {
    if (currentPage !== newPageName) background.deactivate();
  };

  return (
    <>
      <div id="header_pc">
       

        <div className="header_l">

          <Link to="/" className="logo" onClick={() => handleClickLink("home")}>
            <img src={logo_wh} className="white" alt="" width={290} height={58} />
          </Link>

        </div>
        <nav className="header_r">

          <Link to="/works" onClick={() => handleClickLink("works")} className={currentPage === "works" ? " active" : ""}>
            WORKS
          </Link>

          <Link to="/about" onClick={() => handleClickLink("about")} className={currentPage === "about" ? " active" : ""}>
            ABOUT
          </Link>

          <Link to="/news" onClick={() => handleClickLink("news")} className={currentPage === "news" ? " active" : ""}>
            NEWS
          </Link>

          <Link to="/contact" onClick={() => handleClickLink("contact")} className={currentPage === "contact" ? " active" : ""}>
            CONTACT
          </Link>

          <SnsIconList variantClassName="" />
        </nav>
      </div>
    </>
  );
}