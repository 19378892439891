import { Link } from 'react-router-dom';
import useBackground from 'hooks/useBackground'
import arrow from "assets/img/icon/wildcard_arrow_wh.svg";
import { } from 'modules/modules'
type Props = {
  path: string
  letter: string
  external: boolean
  variant: string
}
const PrimaryButton = (props: Props) => {

  const background = useBackground();

  function link() {
    if (props.external) {
      return (
        <a href={props.path} className="link" target="_blank" rel="noopener noreferrer">
          <span className="link_name">{props.letter}</span>
          <span className="arrow"><img className="" src={arrow} alt="" /></span>
        </a>
      )
    } else {
      return (
        <Link to={props.path} className="link" onClick={() => background.deactivate()}>
          <span className="link_name">{props.letter}</span>
          <span className="arrow"><img className="" src={arrow} alt="" /><img className="" src={arrow} alt="" /></span>
        </Link>
      )
    }
  }

  return (
    <div className={"c__primary_button " + props.variant}>
      {link()}
    </div>
  );
}
export default PrimaryButton;