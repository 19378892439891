import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { motion } from "framer-motion"
import { d } from 'modules/cipher'
import { Helmet } from "react-helmet-async"
import noimage from 'assets/img/common/noimage.jpg'
import { loaded, loading, qs, getParam } from 'modules/modules'
import useBackground from 'hooks/useBackground'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryArticle from 'components/layouts/articles/PrimaryArticle'

type Props = {
}

const Show = (props: Props) => {

  const background = useBackground();

  const { id } = useParams(); //ルーティングで使っているパラメータ
  const [article, setArticle] = useState(newArticle("", "", "", ""));
  const [prevArticleId, setPrevArticleId] = useState("");
  const [nextArticleId, setNextArticleId] = useState("");

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  // 記事詳細を取得
  useEffect(() => {
    loading();
    background.deactivate();

    let postTitle: string;
    let publishedDate: string;
    let postContent: string;
    let excerpt: string;

    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `article_detail/?id=${id}${previewQuery}`;
    const uri = apiDomain + "/wp-json/wp/v2/" + query;

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {

        postTitle = String(res.data.title);

        //公開日
        publishedDate = dayjs(res.data.date).format('YYYY-MM-DD');

        // 本文の取得
        postContent = res.data.content;

        excerpt = res.data.content.replace(/(<([^>]+)>)/gi, '');

        // 記事情報のセット
        setArticle(newArticle(postTitle, publishedDate, postContent, excerpt));
        if (res.data.prev) setPrevArticleId(res.data.prev.id);
        if (res.data.next) setNextArticleId(res.data.next.id);

        loaded();
        background.activate();
      })
      .catch(function (e) {
        loaded();
        background.activate();
        window.location.href = "/notfound"
      })
  }, []);

  function newArticle(title: string, published_at: string, content: string, excerpt: string) {
    return { title: title, published_at: published_at, content: content, excerpt: excerpt };
  }

  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  return (
    <>
      <Helmet>
        <meta property="og:image" content="https://wildcard.jp/ogp.jpg" />
        <meta property="og:title" content={article.title + " NEWS - WiLDCARD.inc"} />
        <meta property="og:url" content={"https://wildcard.jp/news/" + id} />
        <title>{article.title} NEWS - WiLDCARD.inc</title>
        {article.excerpt &&
          <>
          <meta property="og:description" content={"NEWS - WiLDCARD.inc " + article.excerpt} />
          <meta name="description" content={"NEWS - WiLDCARD.inc " + article.excerpt} />
          </>
        }
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p_news_show" className="js__load_required l__content_width">

          <PrimaryHeading letter="NEWS" letter_ja="お知らせ" modifire='small' />
          <PrimaryArticle article={article} prevArticleId={prevArticleId} nextArticleId={nextArticleId} mode={"news"} />

        </main>
      </motion.div>
    </>
  );
}
export default Show;