import HeaderPc from './HeaderPc';
import HeaderSp from './HeaderSp';
import PageTopButton from 'components/layouts/footer/PageTopButton';
import { initParallax } from "modules/modules"
import { useEffect } from 'react';

type Props = {
}
export default function Header(props: Props) {

  useEffect(() => {
    const cleanupParallax = initParallax();
    return () => {
      cleanupParallax();
    }
  }, []);

  return (
    <>
      <PageTopButton />
      <header id={"header"}>
        <HeaderPc />
        <HeaderSp />
      </header>
    </>
  );
}