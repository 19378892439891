import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { loaded, loading, getParam, } from '../../../modules/modules'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import { d } from 'modules/cipher'
import Pagenation from '../../ui/Pagenation'
import useBackground from '../../../hooks/useBackground'
import arrow from "assets/img/icon/wildcard_arrow_wh.svg";

type Props = {
  limit: number
  pagenate: boolean
}

export default function NewsList(props: Props) {

  // 背景操作用
  const background = useBackground();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = props.limit;
  const [currentPage, setCurrentPage] = useState(initCurrentPage());
  const [totalPages, setTotalPages] = useState(0);
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  const apiDomain = d(process.env.REACT_APP_BACKEND_API_DOMAIN);

  // 初回画面表示時に選択されているページ数を取得
  function initCurrentPage(): number {
    let params: number = parseInt(getParam("page"));
    if (params) {
      return params
    } else {
      return 1
    }
  }

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `article_list?limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = apiDomain + "/wp-json/wp/v2/" + query;
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    loading();
    background.deactivate();
    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 5000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
        setTotalPages(parseInt(res.data.total_pages)); //総ページ数 
      })
      .catch(function (error) {
      })
      .finally(function () {
        loaded();
        background.activate();
      });
  }, [currentPage, routeUniqueKey]);

  const showArticles = useMemo(() => {
    let postTitle;
    let postUrl;
    let publishedDate;
    // let postCategories: any;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles?.length >= 1) {
      return (

        <>
          <div className="c___news_list">
            {
              articles.map((article: any, index: number) => {
                // タイトルの取得
                postTitle = String(article.title);
                // URLの取得
                postUrl = "/news/" + article.id + previewQuery;
                //公開日
                publishedDate = dayjs(article.date).format('YYYY.MM.DD');
                return (

                  <Link to={postUrl} className="news_item" title={postTitle} onClick={() => background.deactivate()} key={index}>

                    <div className="date">
                      {publishedDate}
                    </div>

                    <div className="title">
                      <span className="link_name">{postTitle}</span>
                      <span className="arrow"><img className="" src={arrow} alt="" /></span>
                    </div>
                  </Link>

                )
              })}
          </div>
        </>
      )
    } else {
      return (
        <p className="article_notfound c__common_text">
          <span className="u__ib">データが見つかりませんでした。</span>
        </p>
      )
    }
  }, [articles])

  return (
    <>
      <div className="js__load_required" key={routeUniqueKey}>
        {showArticles}
      </div>
      {props.pagenate && totalPages >= 2 &&
        <Pagenation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages} />}
    </>
  );
}