import { gsap } from "gsap";

// @ts-ignore
import TweenMax from "gsap";
// import Back from "gsap";

// @ts-ignore
import Stats from 'stats';

import { getRandomInteger, qs } from './modules';

//////////////////////////////
// Demo Functions https://codepen.io/jhnsnc/pen/Mprdaa
//////////////////////////////

let requestAnimationFrameId = 0;

export function geometryInit() {


  // stats
  // メモリの使用状況など表示
  // if (showStats) {
  //   let stats: any = new Stats();
  //   stats.domElement.style.position = 'absolute';
  //   stats.domElement.style.left = '0';
  //   stats.domElement.style.top = '0';
  //   document.body.appendChild(stats.domElement);
  //   requestAnimationFrame(function updateStats() {
  //     stats.update();
  //     requestAnimationFrame(updateStats);
  //   });
  // }

  // init
  const svg = qs('#geometryangle');
  tesselation.setup(svg);
  gradients.setup();

  let lastTransitionAt: any;
  const transitionDelay = 5500
  const transitionDuration = 4000;


  function playNextTransition() {
    tesselation.next(transitionDuration);
    gradients.next(transitionDuration);
  };


  function tick(time: number) {
    if (!lastTransitionAt || time - lastTransitionAt > transitionDelay) {
      lastTransitionAt = time;
      playNextTransition();
    }
    requestAnimationFrameId = window.requestAnimationFrame(tick);
  }
  requestAnimationFrameId = window.requestAnimationFrame(tick);
}

export function geometryDestroy() {
  window.cancelAnimationFrame(requestAnimationFrameId);
}

//////////////////////////////
// Delaunay Triangulation
//////////////////////////////

const calcDelaunayTriangulation = (function () {
  const EPSILON = 1.0 / 1048576.0;
  function getSuperT(vertices: any) {
    let xMin = Number.POSITIVE_INFINITY, yMin = Number.POSITIVE_INFINITY,
      xMax = Number.NEGATIVE_INFINITY, yMax = Number.NEGATIVE_INFINITY,
      i, xDiff, yDiff, maxDiff, xCenter, yCenter;
    for (i = vertices.length; i--;) {
      if (vertices[i][0] < xMin) xMin = vertices[i][0];
      if (vertices[i][0] > xMax) xMax = vertices[i][0];
      if (vertices[i][1] < yMin) yMin = vertices[i][1];
      if (vertices[i][1] > yMax) yMax = vertices[i][1];
    }
    xDiff = xMax - xMin;
    yDiff = yMax - yMin;
    maxDiff = Math.max(xDiff, yDiff);
    xCenter = xMin + xDiff * 0.5;
    yCenter = yMin + yDiff * 0.5;
    return [
      [xCenter - 20 * maxDiff, yCenter - maxDiff],
      [xCenter, yCenter + 20 * maxDiff],
      [xCenter + 20 * maxDiff, yCenter - maxDiff]
    ];
  }
  function circumcircle(vertices: any, i: any, j: any, k: any) {
    let xI = vertices[i][0], yI = vertices[i][1],
      xJ = vertices[j][0], yJ = vertices[j][1],
      xK = vertices[k][0], yK = vertices[k][1],
      yDiffIJ = Math.abs(yI - yJ), yDiffJK = Math.abs(yJ - yK),
      xCenter, yCenter, m1, m2, xMidIJ, xMidJK, yMidIJ, yMidJK, xDiff, yDiff;
    // bail condition
    if (yDiffIJ < EPSILON && yDiffJK < EPSILON)
      throw new Error("Can't get circumcircle since all 3 points are y-aligned");
    // calc circumcircle center x/y, radius
    m1 = -((xJ - xI) / (yJ - yI));
    m2 = -((xK - xJ) / (yK - yJ));
    xMidIJ = (xI + xJ) / 2.0;
    xMidJK = (xJ + xK) / 2.0;
    yMidIJ = (yI + yJ) / 2.0;
    yMidJK = (yJ + yK) / 2.0;
    xCenter = (yDiffIJ < EPSILON) ? xMidIJ :
      (yDiffJK < EPSILON) ? xMidJK :
        (m1 * xMidIJ - m2 * xMidJK + yMidJK - yMidIJ) / (m1 - m2);
    yCenter = (yDiffIJ > yDiffJK) ?
      m1 * (xCenter - xMidIJ) + yMidIJ :
      m2 * (xCenter - xMidJK) + yMidJK;
    xDiff = xJ - xCenter;
    yDiff = yJ - yCenter;
    // return
    return { i: i, j: j, k: k, x: xCenter, y: yCenter, r: xDiff * xDiff + yDiff * yDiff };
  }
  function dedupeEdges(edges: any) {
    let i, j, a, b, m, n;
    for (j = edges.length; j;) {
      b = edges[--j]; a = edges[--j];
      for (i = j; i;) {
        n = edges[--i]; m = edges[--i];
        if ((a === m && b === n) || (a === n && b === m)) {
          edges.splice(j, 2); edges.splice(i, 2);
          break;
        }
      }
    }
  }
  return function (vertices: any) {
    let n = vertices.length,
      i, j, indices, st, candidates, locked, edges, dx, dy, a, b, c;
    // bail if too few / too many verts
    if (n < 3 || n > 2000)
      return [];
    // copy verts and sort indices by x-position
    vertices = vertices.slice(0);
    indices = new Array(n);
    for (i = n; i--;)
      indices[i] = i;
    indices.sort(function (i, j) {
      return vertices[j][0] - vertices[i][0];
    });
    // supertriangle
    st = getSuperT(vertices);
    vertices.push(st[0], st[1], st[2]);
    // init candidates/locked tris list
    candidates = [circumcircle(vertices, n + 0, n + 1, n + 2)];
    locked = [];
    edges = [];
    // scan left to right
    for (i = indices.length; i--; edges.length = 0) {
      c = indices[i];
      // check candidates tris against point
      for (j = candidates.length; j--;) {
        // lock tri if point to right of circumcirc
        dx = vertices[c][0] - candidates[j].x;
        if (dx > 0.0 && dx * dx > candidates[j].r) {
          locked.push(candidates[j]);
          candidates.splice(j, 1);
          continue;
        }
        // point outside circumcirc = leave candidates
        dy = vertices[c][1] - candidates[j].y;
        if (dx * dx + dy * dy - candidates[j].r > EPSILON)
          continue;
        // point inside circumcirc = break apart, save edges
        edges.push(
          candidates[j].i, candidates[j].j,
          candidates[j].j, candidates[j].k,
          candidates[j].k, candidates[j].i
        );
        candidates.splice(j, 1);
      }
      // new candidates from broken edges
      dedupeEdges(edges);
      for (j = edges.length; j;) {
        b = edges[--j];
        a = edges[--j];
        candidates.push(circumcircle(vertices, a, b, c));
      }
    }
    // close candidates tris, remove tris touching supertri verts
    for (i = candidates.length; i--;)
      locked.push(candidates[i]);
    candidates.length = 0;
    for (i = locked.length; i--;)
      if (locked[i].i < n && locked[i].j < n && locked[i].k < n)
        candidates.push(locked[i].i, locked[i].j, locked[i].k);
    // done
    return candidates;
  };
})();

// ポリゴンを領域に充填させる
const tesselation = (function () {
  let svg: any
  let svgW: any
  let svgH: any
  let prevGroup: any;
  let groupClassName: any;

  function createRandomTesselation() {
    const drawArea = qs('#contact_bg_wrapper');
    let wW = window.innerWidth;
    let wH = window.innerHeight;

    let gridSpacing = 200, scatterAmount = 0.75;
    let gridSize, i, x, y;

    if (wW / wH > svgW / svgH) { // window wider than svg = use width for gridSize
      gridSize = gridSpacing * svgW / wW;
    } else { // window taller than svg = use height for gridSize
      gridSize = gridSpacing * svgH / wH;
    }

    let vertices = [];
    let xOffset = (svgW % gridSize) / 2, yOffset = (svgH % gridSize) / 2;
    for (x = Math.floor(svgW / gridSize) + 1; x >= -1; x--) {
      for (y = Math.floor(svgH / gridSize) + 1; y >= -1; y--) {
        vertices.push(
          [
            xOffset + gridSize * (x + scatterAmount * (Math.random() - 0.5)),
            yOffset + gridSize * (y + scatterAmount * (Math.random() - 0.5))
          ]
        );
      }
    }

    let triangles: any = calcDelaunayTriangulation(vertices);

    let group = document.createElementNS('http://www.w3.org/2000/svg', 'g');

    // 現在時刻を取得
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const second = now.getSeconds();
    const millisecond = now.getMilliseconds();
    const time = hour + minute + second + millisecond;
    groupClassName = 'tesselation' + time;
    group.setAttribute('class', groupClassName);

    let polygon;

    const polygonColors = [ // 14 colors - use 3-5 span
      '#000', // ultramarine50
      '#6a14b1', // aqua40
      '#34089b', // aqua40
      '#9b0883', // aqua40
    ];

    for (i = triangles.length; i;) {
      polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
      polygon.setAttribute('points',
        vertices[triangles[--i]][0] + ',' + vertices[triangles[i]][1] + ' ' +
        vertices[triangles[--i]][0] + ',' + vertices[triangles[i]][1] + ' ' +
        vertices[triangles[--i]][0] + ',' + vertices[triangles[i]][1]
      );
      polygon.setAttribute("fill", polygonColors[getRandomInteger(0, 3)])
      group.appendChild(polygon);
    }

    return group;
  }

  return {
    setup: function (svgElement: any) {
      svg = svgElement;
      let vb = svg.getAttribute('viewBox').split(/\D/g);
      svgW = vb[2];
      svgH = vb[3];
    },
    next: function (t: any) {
      let toRemove, i, n;
      t /= 1000;

      if (prevGroup && prevGroup.children && prevGroup.children.length) {
        toRemove = prevGroup;
        n = toRemove.children.length;
        for (i = n; i--;) {
          TweenMax.to(toRemove.children[i], t * 0.4, { opacity: 0, delay: t * (0.3 * i / n) });
        }
        // gsap.delayedCall(t * (0.7 + 0.05), function (group: any) { svg.removeChild(group); }, [toRemove], this);
        // TweenMax.delayedCall(t * (0.7 + 0.05), function (group: any) { svg.removeChild(group.firstElementChild) }, [toRemove]);
        TweenMax.delayedCall(t * (0.7 + 0.05), function (group: any) {
          if (group.parentNode === svg) {
            svg.removeChild(group)
          }
        }, [toRemove]);
        // TweenMax.delayedCall(t * (0.7 + 0.05), function (group: any) { svg.querySelector("g").remove() }, [toRemove]);
        // TweenMax.delayedCall(t * (0.7 + 0.05), function (group: any) { prevGroup.remove() }, [toRemove]);
      }
      let g = createRandomTesselation();
      n = g.children.length;
      for (i = n; i--;) {
        TweenMax.fromTo(g.children[i], t * 0.4, { opacity: 0 }, { opacity: 0.3 + 0.25 * Math.random(), delay: t * (0.3 * i / n + 0.3), ease: "back.out" });
      }
      svg.appendChild(g);
      prevGroup = g;
    }
  }
})();

//////////////////////////////
// Gradients
//////////////////////////////

const gradients = (function () {
  let grad1: any, grad2: any, showingGrad1: any;

  // using colors from IBM Design Colors this time
  const colorsA = [ // 14 colors - use 3-5 span
    '#af1316', // ultramarine50
    '#970306', // teal40
    '#87070a', // aqua40
    '#640000', // aqua40
  ];
  const colorsB = [ // 14 colors - use 3-5 span
    '#780000', // ultramarine50
    '#530506', // teal40
    '#340000', // aqua40
    '#270306', // teal40
  ];

  function assignRandomColors(gradObj: any) {
    const rA = getRandomInteger(0, 3);
    const rB = getRandomInteger(0, 3);
    // let rA = Math.floor(colors.length * Math.random());
    // let rB = Math.floor(Math.random() * 3) + 3; // [3 - 5]
    // rB = (rA + (rB * (Math.random() < 0.5 ? -1 : 1)) + colors.length) % colors.length;
    gradObj.stopA.setAttribute('stop-color', colorsA[rA]);
    gradObj.stopB.setAttribute('stop-color', colorsB[rB]);
  }

  return {
    setup: function () {
      showingGrad1 = false;
      grad1 = {
        stopA: document.getElementById('stop1a'),
        stopB: document.getElementById('stop1b'),
        rect: document.getElementById('rect1')
      };
      grad2 = {
        stopA: document.getElementById('stop2a'),
        stopB: document.getElementById('stop2b'),
        rect: document.getElementById('rect2')
      };
      grad1.rect.style.opacity = 0;
      grad2.rect.style.opacity = 0;
    },
    next: function (t: any) {
      t /= 1000;

      let show, hide;
      if (showingGrad1) {
        hide = grad1;
        show = grad2;
      } else {
        hide = grad2;
        show = grad1;
      }
      showingGrad1 = !showingGrad1;

      gsap.to(hide.rect, 0.55 * t, { opacity: 0, delay: 0.2 * t, ease: "sine.out" });
      assignRandomColors(show);
      gsap.to(show.rect, 0.65 * t, { opacity: 1, ease: "sine.in" });
    }
  };
})();