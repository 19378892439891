import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import useBackground from '../../../hooks/useBackground'
import { } from '../../../modules/modules'
type Props = {
}

const NotFound = (props: Props) => {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();
  }, []);

  return (
    <>
      <Helmet>
        <title>お探しのページが見つかりませんでした｜WiLDCARD.inc</title>
        <meta name="description" content={"お探しのページが見つかりませんでした｜WiLDCARD.inc"} />
        <meta property="og:url" content={"https://wildcard.jp/404"} />
        <meta property="og:title" content={"お探しのページが見つかりませんでした｜WiLDCARD.inc"} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__notfound" className="l__content_width">
          <h2 className="c__head_primary">404 <span className="u__ib">NOT FOUND</span></h2>
          <p className="c__common_text">
            お探しのページが見つかりませんでした。
          </p>
          <Link to={"/"} className="c__secondary_button" id="submit" onClick={() => background.deactivate()} >
            TOPへ戻る
          </Link>
        </main>
      </motion.div>
    </>
  );
}
export default NotFound;