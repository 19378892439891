// @ts-ignore
import UAParser from 'ua-parser-js';

/*=======================================
  IntersectionObserver系
=======================================*/

// IntersectionObserver共通関数
export function ISOvserve(targetSelector: string | string[], options: any, activeFunc: any, inactiveFunc: any = () => { }): IntersectionObserver {

  let observer = new IntersectionObserver(callback(activeFunc, inactiveFunc), options);
  if (Array.isArray(targetSelector)) {
    const targets = qsAll(targetSelector.join(", "));
    for (const target of targets) {
      observer.observe(target);
    }
  } else {
    const target = qs(targetSelector);
    observer.observe(target);
  }
  return observer;

  function callback(activeFunc: any, inactiveFunc: any) {
    return function (entries: any) {
      for (const elm of entries) {
        if (elm.isIntersecting) {
          activeFunc(elm);
        } else {
          inactiveFunc(elm);
        }
      }
    }
  }
}

export function ISOvserver(selector: string | string[] = ""): IntersectionObserver {
  const targetSelectors = selector ? selector : [".c__js_fade", ".c__js_fade_delay", ".c__js_blur", ".js__transition", ".js__animation", ".js__observe", ".c__js_highlights"];
  // ネガティブマージンにすると当たり判定を狭められて、
  // 普通のマージンにすると当たり判定を画面外まで広げられる
  const options = {
    rootMargin: "0% 0% -40% 0%", // ビューポートの真ん中ぐらい
  };
  function activeFunc(elm: any) {
    elm.target.classList.add("active");
  }
  return ISOvserve(targetSelectors, options, activeFunc)
}

export function ISOvserverRepeats(selector: string | string[] = ""): IntersectionObserver {
  const targetSelectors = selector ? selector : [".js__observe_repeat", ".c__js_fade_repeat"];
  const options = {
    rootMargin: "0% 0% -40% 0%", // ビューポートの真ん中ぐらい
  };
  function activeFunc(elm: any) {
    elm.target.classList.add("active");
  }
  function inactiveFunc(elm: any) {
    elm.target.classList.remove("active");
  }
  return ISOvserve(targetSelectors, options, activeFunc, inactiveFunc)
}

// 任意で設定できるIntersectionObserver
export function optionalISOvserver(targetSelectors: string[], options: object, activeFunc: Function, inactiveFunc: Function): IntersectionObserver {
  return ISOvserve(targetSelectors, options, activeFunc, inactiveFunc)
}


/*=======================================
  ハンバーガーメニューまわり
=======================================*/
export function humbergerMenuUtils() {
  const toggle = qs("#header_toggle");
  const spMenu = qs("#sp_menu");
  const overlay = qs("#overlay");
  toggle!.addEventListener("click", () => {
    toggleMenu();
  });
  overlay!.addEventListener("click", () => {
    toggleMenu();
  });

  const spMenuLinks = qsAll("#sp_menu a");

  qsAll("a", spMenu);

  for (const elm of spMenuLinks) {
    elm.addEventListener("click", () => {
      toggleMenu();
    });
  }

  function toggleMenu() {
    toggleClass(toggle, "active");
    toggleClass(spMenu, "active");
    toggleClass(overlay, "active");
    if (hasClass(toggle, "active")) {
      preventScroll();
    } else {
      arrowScroll();
    }
  }
}

function preventScroll() {
  document.addEventListener("wheel", (preventDef), { passive: false });
  document.addEventListener("touchmove", preventDef, { passive: false });
}
function arrowScroll() {
  document.removeEventListener("wheel", preventDef);
  document.removeEventListener("touchmove", preventDef);
}
// スクロール禁止関数
function preventDef(e: any) {
  e.preventDefault();
}

/*=======================================
  スムーススクロール系
=======================================*/
export function initSmoothAnchorScroll() {
  const scrollAnchorArr = Array.prototype.slice.call(qsAll('a[href^="#"]'));
  let targetOffsetTop: number;

  // ページ内でのアンカーの場合
  for (const link of scrollAnchorArr) {
    link.addEventListener('click', (e: any) => {
      e.preventDefault();
      const targetElement = qs(link.hash);
      if (targetElement) {
        targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      } else {
        targetOffsetTop = 0;
      }
      scrollTo(targetOffsetTop)
    });
  }

  // ページ外からのアンカーの場合
  const anchorId = window.location.hash
  if (anchorId.indexOf("#") !== -1) {
    const targetElement = qs(anchorId)
    if (targetElement) {
      targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
    } else {
      targetOffsetTop = 0;
    }
    scrollTo(targetOffsetTop)
  }
}


/*=======================================
  上へ戻るボタン
=======================================*/
export function initPageTopButton() {
  const targetSelectors = ["#first_view"];
  const pagetopButton = qs('#pagetop_button');

  // 表示非表示の制御
  const options = {
    rootMargin: "170px 0px 0px 0px",
  };
  function activeFunc(elm: any) {
    pagetopButton!.classList.remove('active');
  }
  function inactiveFunc(elm: any) {
    pagetopButton!.classList.add('active');
  }
  ISOvserve(targetSelectors, options, activeFunc, inactiveFunc)

  // クリックで上へ移動
  pagetopButton!.addEventListener('click', (e: any) => {
    e.preventDefault();
    scrollTo(0)
  });
}

/*=======================================
  パララックス処理
=======================================*/
export function initParallax(): Function {
  const parallaxTargets: any = qsAll('.js__parallax_container');
  const isLandscape = (window.innerWidth - window.innerHeight) > 0; // 端末が横長かどうか
  const base = 0.3;
  let parallaxMagnification: number
  for (const elm of parallaxTargets) {
    const magnifClass = elm.className.match(/js__parallax_magnif_./);
    const magnif = magnifClass ? Number(magnifClass[0].slice(-1)) : 1
    const baseMagnif = base * magnif
    parallaxMagnification = isLandscape ? baseMagnif : baseMagnif / 2 // 端末が縦長だった場合は倍率を低くする
    window.addEventListener("scroll", () => { parallax(elm) });
  }
  return cleanupParallax;
  function parallax(elm: HTMLElement): void {
    elm.style.transform = `translateY(-${window.pageYOffset * parallaxMagnification}px)`;
  }
  function cleanupParallax(): void {
    for (const elm of parallaxTargets) {
      window.removeEventListener("scroll", () => { parallax(elm) });
    }
  }
}

/*=======================================
  その他関数
=======================================*/
export function setSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--svh', `${vh}px`)
  }, 500);
}
export function setStaticSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--static_svh', `${vh}px`);
  }, 500);
}
export function toggleClass(element: any, className: string): void {
  if (element.className.includes(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
}
export function scrollTo(offset: number): void {
  window.scrollTo({
    top: offset,
    behavior: "smooth"
  });
}
export function scrollToId(id: string): void {
  const target = qs("#" + id)
  if (target) scrollTo(target.offsetTop)
}
export function getHeaderHeight(): number {
  const pc_header_selector = "#header .header_l";
  const sp_header_selector = "#sp_menu .sp_menu_logo";
  const pcHeaderHeight = qs(pc_header_selector)!.offsetHeight;
  const tabHeaderHeight = qs(sp_header_selector)!.offsetHeight;
  return pcHeaderHeight + tabHeaderHeight + 40;
}
export function getParam(param: string): string {
  try {
    let url = new URL(window.location.href);
    let value = url.searchParams.get(param);
    return value ? value : "";
  }
  catch (e) {
    return "";
  }
}
export function getParamFromUrlString(urlString: string, param: string): string {
  try {
    let url = new URL(urlString);
    let value = url.searchParams.get(param);
    return value ? value : "";
  }
  catch (e) {
    return "";
  }
}
export function getPath(): string {
  return window.location.pathname;
}
export function getHash(): string {
  return window.location.hash;
}
export function getPathHash(): string {
  return window.location.pathname + window.location.hash;
}
export function isActive(element: HTMLElement): boolean {
  return element?.className.includes("active");
}
export function qs(name: string, elm = document): any {
  return elm?.querySelector(name);
}
export function qsAll(name: string, elm: any = document): any {
  return elm?.querySelectorAll(name);
}
export function hasClass(elm: HTMLElement | null, name: string): boolean {
  return elm?.className.includes(name)!!; // undefinedの場合はfalseにする
}
export function addClass(elm: HTMLElement | null, name: string): void {
  return elm?.classList.add(name);
}
export function removeClass(elm: HTMLElement | null, name: string): void {
  if (elm && hasClass(elm, name)) {
    elm.classList.remove(name);
  }
}
export function loaded(): void {
  let loader = qsAll(".js__load_required");
  for (const elm of loader) {
    addClass(elm, "loaded");
  }
}
export function loading(): void {
  let loader = qsAll(".js__load_required");
  for (const elm of loader) {
    removeClass(elm, "loaded");
  }
}
export function unEscapeHtml(escapedText: string) {
  // 参考リンクhttps://blog.kimizuka.org/entry/2022/02/28/214035
  const textarea = document.createElement('textarea');
  textarea.innerHTML = escapedText;
  return textarea.value;
}

function isMobile(): boolean {
  return UAParser().device.type === 'mobile'
}

/**
* ランダムな正の整数を生成
* @param {number} min - 最小値
* @param {number} max - 最大値
* @param {number} offset - 結果を若干上振れor下振れさせたい場合に使用
* @return {number} ランダムな整数
* @note offsetに小数を渡した場合は小数が返却される
* @note 0が返却される可能性もあり
*/
export function getRandomInteger(min: number, max: number, offset: number = 0) {
  const num = (Math.floor(Math.random() * (max + 1 - min)) + min) + offset;
  return num <= 0 ? 0 : num;
}

/**
* 小数点の桁数をを指定し、四捨五入して返す
* @param {number} number - 対象となる数値 
* @param {number} digit - 小数点の桁数
* @return {number} 四捨五入した値
* @note Math.pow() は累乗を算出する関数で、 Math(x, y) はxのy乗を表す
*/
export function round(number: number, digit: number) {
  const magnification = Math.pow(10, digit);
  return Math.round(number * magnification) / magnification;
};
